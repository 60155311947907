const apiurl = "gw.sakagrup.com";
const apiport = "8443";

module.exports = {
  api: {
    Login: `https://${apiurl}:${apiport}/api/v1/login/request`,
    User: `https://${apiurl}:${apiport}/api/v1/users/`,
    Group: `https://${apiurl}:${apiport}/api/v1/users/user/`,
  },
  groups: {
    3: {
      visibleItems: ['Area','Docs','Reports','Dashboard','Moduller','BetterStands'],
      roles: [1,2,3,4,5,6,7,8]
    },
    default: {
      visibleItems: ['Area','Docs','Reports','Dashboard','Moduller','BetterStands'],
      roles: []
    }
  }
};
