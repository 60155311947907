import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from "react-router-dom";
import axios from 'axios';
import config from '../slices/config.js';
import { authProtectedRoutes } from './allRoutes.js';

const AuthProtected = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchGroupInfo = async () => {
      try {
        const authHeader = 'Basic ' + btoa('fds_mobile:Rk3%${Vx]x');
        const user = JSON.parse(localStorage.getItem('user'));
        let groupInfo = JSON.parse(localStorage.getItem('groupInfo'));

        if (user && user.id && token) {
          if (!groupInfo) {
            const groupResponse = await axios.get(`${config.api.Group}${user.id}/group`, {
              headers: {
                Authorization: authHeader,
              },
            });
            groupInfo = groupResponse.data;
            localStorage.setItem('groupInfo', JSON.stringify(groupInfo));
          }

          const currentRoute = authProtectedRoutes.find(route => route.path.toLowerCase() === location.pathname.toLowerCase());
          if (currentRoute && currentRoute.roles) {
            const userGroupId = groupInfo.id;
            if (currentRoute.roles.includes(userGroupId)) {
              setIsAuthorized(true);
            } else {
              setIsAuthorized(false);
            }
          } else {
            setIsAuthorized(true);
          }
        }
      } catch (error) {
        console.error("Authorization error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroupInfo();
  }, [token, location.pathname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthorized ? children : <Navigate to="/login" />;
};

export { AuthProtected };
