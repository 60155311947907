import React from "react";
import { Navigate } from "react-router-dom";
import Login from "../pages/Login/index.js";
import Area from "../pages/Area/index.js";
import Docs from "../pages/Docs/index.js";
import Reports from "../pages/Reports/index.js";

const authProtectedRoutes = [
  { path: "/Area", component: <Area />, roles: [1,2,3,4,5,6,7,8] },
  { path: "/Docs", component: <Docs />, roles: [1,2,3,4,5,6,7,8] },
  { path: "/Reports", component: <Reports />, roles: [1,2,3,4,5,6,7,8] },
  { path: "/", exact: true, component: <Navigate to="/Area" /> },
  { path: "*", component: <Navigate to="/Area" /> },
];

const publicRoutes = [
  { path: "/Login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
