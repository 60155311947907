import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardImg, Button, Modal, ModalBody, ModalHeader, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';

const Dosyalar = () => {
    const [images, setImages] = useState([]);  // Kullanıcının fotoğraflarını tutar
    const [loading, setLoading] = useState(false);  // Yüklenme durumu
    const [error, setError] = useState(null);  // Hata mesajlarını tutar
    const [stands, setStands] = useState([]);  // Standlar için state
    const [categories, setCategories] = useState([]);  // Kategoriler için state
    const [selectedImage, setSelectedImage] = useState(null);  // Düzenlenen fotoğraf
    const [modalOpen, setModalOpen] = useState(false);  // Modal açılıp kapanma durumu
    const [selectedStand, setSelectedStand] = useState('');  // Seçilen stand ID
    const [selectedCategory, setSelectedCategory] = useState('');  // Seçilen kategori ID
    const [standSearch, setStandSearch] = useState('');  // Stand arama
    const [categorySearch, setCategorySearch] = useState('');  // Kategori arama

    const userId = localStorage.getItem('userId');  // LocalStorage'den userId'yi alıyoruz

    // Kullanıcının fotoğraflarını backend'den almak
    const fetchUserImages = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`https://e.sakagrup.com/user/${userId}/images`);
            setImages(response.data);  // Gelen verileri state'e set ediyoruz
        } catch (err) {
            console.error('Fotoğraflar alınırken bir hata oluştu:', err);
            setError('Fotoğraflar yüklenirken bir hata oluştu.');
        }
        setLoading(false);
    };

    // Stand ve kategori bilgilerini almak için
    const fetchStandAndCategories = async () => {
        try {
            const [standsResponse, categoriesResponse] = await Promise.all([
                axios.get('https://e.sakagrup.com/standss'),  // Standları alıyoruz
                axios.get('https://e.sakagrup.com/categories')  // Kategorileri alıyoruz
            ]);
            setStands(standsResponse.data);
            setCategories(categoriesResponse.data);
        } catch (err) {
            console.error('Standlar ve kategoriler alınırken bir hata oluştu:', err);
        }
    };

    // İlk yüklemede fotoğrafları ve stand/kategorileri çekmek için
    useEffect(() => {
        fetchUserImages();
        fetchStandAndCategories();
    }, []);

    // Modal açma fonksiyonu
    const openModal = (image) => {
        setSelectedImage(image);
        setSelectedStand(image.standid || '');  // Varsayılan olarak mevcut standı göster
        setSelectedCategory(image.categoryid || '');  // Varsayılan olarak mevcut kategoriyi göster
        setModalOpen(true);
    };

    // Modal kapatma fonksiyonu
    const closeModal = () => {
        setSelectedImage(null);
        setSelectedStand('');
        setSelectedCategory('');
        setModalOpen(false);
    };

    // Stand ve kategori eşlemesi kaydetme
    const saveStandAndCategory = async () => {
        try {
            // Eğer kategori seçildiyse ve stant seçilmemişse, uyarı göster ve işlemi durdur
            if (selectedCategory && !selectedStand) {
                alert('Lütfen bir stant seçin. Kategori seçildiğinde bir stant seçilmesi zorunludur.');
                return;
            }

            // Eğer kategori seçilmediyse, null olarak gönderiyoruz
            const categoryToSend = selectedCategory ? selectedCategory : null;

            await axios.put(`https://e.sakagrup.com/image/${selectedImage.id}/update`, {
                standid: selectedStand || null,  // Stand seçilmediyse null olarak gönderiliyor
                categoryid: categoryToSend || null,  // Kategori seçilmediyse null olarak gönderiliyor
            });

            // Başarılı olursa fotoğrafları yeniden yükle
            fetchUserImages();
            closeModal();
        } catch (err) {
            console.error('Stand ve kategori kaydedilirken bir hata oluştu:', err);
            alert('Kaydetme işlemi başarısız oldu.');
        }
    };


    // Kategorisi belirtilmemiş olan ve Stand ID'si olmayan fotoğrafları filtreleyin
    const filteredImages = images.filter(image => !image.categoryid && !image.standid);

    return (
        <div className="page-content">
            <Container fluid>
                <h1>Dosyalarım</h1>

                {/* Yüklenme durumu veya hata mesajları */}
                {loading && <p>Fotoğraflar yükleniyor...</p>}
                {error && <p style={{ color: 'red' }}>{error}</p>}

                <Row>
                    {filteredImages.length > 0 ? (
                        filteredImages.map((image) => (
                            <Col xs="6" sm="6" md="3" key={image.id} className="mb-4"> {/* Telefon modunda iki kart yan yana */}
                                <Card onClick={() => openModal(image)} style={{ cursor: 'pointer' }}>
                                    <CardImg top width="100%" src={image.photopath} alt="Yüklenen fotoğraf" />
                                    <CardBody>
                                        <p>Kategori: Belirtilmemiş</p>
                                        <p>Stant ID: {image.standid || 'Belirtilmemiş'}</p>
                                        <p>Rapor Tipi: {image.report_type}</p>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <p>Hiç belirtilmemiş kategoriye ait ve Stand ID'si olmayan fotoğraf bulunamadı.</p>
                    )}
                </Row>

                {/* Modal: Stand ve Kategori Eşlemesi */}
                <Modal isOpen={modalOpen} toggle={closeModal}>
                    <ModalHeader toggle={closeModal}>Stand ve Kategori Eşlemesi</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="standSearch">Stand Seç</Label>
                            <Input
                                type="text"
                                id="standSearchInput"
                                value={standSearch}
                                onChange={(e) => setStandSearch(e.target.value)}
                                placeholder="Stand kodu veya ismi ile arayın"
                            />
                            <Input
                                className='mt-3'
                                type="select"
                                id="standSearch"
                                value={selectedStand}
                                onChange={(e) => setSelectedStand(e.target.value)}
                            >
                                <option value="">Stand kodu veya ismi ile arayın</option>
                                {stands
                                    .filter((stand) =>
                                        stand.isim.toLowerCase().includes(standSearch.toLowerCase()) ||
                                        stand.kod.toLowerCase().includes(standSearch.toLowerCase())
                                    )
                                    .map((stand) => (
                                        <option key={stand.id} value={stand.id}>
                                            {stand.kod} - {stand.isim}
                                        </option>
                                    ))}
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label for="categorySearch">Kategori Seç</Label>
                            <Input
                                type="select"
                                id="categorySearch"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                            >
                                <option value={null}>Belirtilmedi</option>
                                {categories
                                    .filter((category) =>
                                        category.kategori_adi.toLowerCase().includes(categorySearch.toLowerCase())
                                    )
                                    .map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.kategori_adi.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                        </option>
                                    ))}
                            </Input>
                        </FormGroup>

                        <Button color="success" onClick={saveStandAndCategory}>Kaydet</Button>
                    </ModalBody>
                </Modal>
            </Container>
        </div>
    );
};

export default Dosyalar;
