import React, { useState, useEffect, useRef } from 'react';
import { Container, Table, Button, Row, Col, Input, Card, CardBody, CardImg, Collapse, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody } from 'reactstrap';
import axios from 'axios';

import Evet from "../../assets/images/Evet.png";
import Hayır from "../../assets/images/Hayır.png";
import Yok from "../../assets/images/Yok.png";
import Belirtilmedi from "../../assets/images/Belirtilmedi.png";

const Area = () => {
  const [stands, setStands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState(null);
  const [reportType, setReportType] = useState('fuaroncesi');
  const [reportTypee, setReportTypee] = useState('fuaroncesi');
  const [selectedStandId, setSelectedStandId] = useState(null);
  const [selectedSalon, setSelectedSalon] = useState('');
  const [selectedStandType, setSelectedStandType] = useState(null);
  const [reportExists, setReportExists] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [cameraError, setCameraError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [maxZoom, setMaxZoom] = useState(1);
  const [capturedPhotos, setCapturedPhotos] = useState([]);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [unmatchedPhotos, setUnmatchedPhotos] = useState([]); // Eşlenmemiş fotoğrafları tutmak için
  const [openIcons, setOpenIcons] = useState({});
  const [selectedPhoto, setSelectedPhoto] = useState(null); // Seçilen fotoğraf için state
  const [cameraActive, setCameraActive] = useState(true);
  const [categoryPhotos, setCategoryPhotos] = useState({}); // Kategorilere göre görselleri saklayacak state
  const [openCategory, setOpenCategory] = useState(null);
  const [isFuaroncesiOpen, setIsFuaroncesiOpen] = useState(reportType === 'fuaroncesi');
  const [isFuarsonrasiOpen, setIsFuarsonrasiOpen] = useState(reportType === 'fuarsonrasi');
  const [isFrontCamera, setIsFrontCamera] = useState(true);
  const [currentDeviceId, setCurrentDeviceId] = useState(null);
  const [availableDevices, setAvailableDevices] = useState([]);
  const [reportStatuses, setReportStatuses] = useState({}); // Badge renklerini tutmak için state
  const [reportStatusess, setReportStatusess] = useState({}); // Badge renklerini tutmak için state
  const videoRef = useRef(null);
  const [LIMIT, setLIMIT] = useState(10);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const userId = localStorage.getItem('userId');
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  // Görsele tıklandığında modal'ı açmak için fonksiyon
  const handleImageClick = (imageSrc) => {
    setModalImage(imageSrc); // Tıklanan görseli modal içinde göstermek için kaydediyoruz
    toggleModal(); // Modal'ı aç
  };

  const handleLimitChange = (limit) => {
    setLIMIT(limit); // LIMIT değerini günceller
    setCurrentPage(1); // Sayfayı başa alır
  };

  useEffect(() => {
    if (selectedStandId) {
      updateCategoryPhotos(11);
    }
  }, [selectedStandId]);

  useEffect(() => {
    fetchStands();  // LIMIT değiştiğinde verileri tekrar çek
  }, [LIMIT]);  // LIMIT değiştiğinde bu useEffect tetiklenecek


  const getAvailableDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput'); // Sadece videoinput cihazları

      if (videoDevices.length > 0) {
        setAvailableDevices(videoDevices); // Tüm video cihazlarını set ediyoruz
        setCurrentDeviceId(videoDevices[0].deviceId); // Varsayılan olarak ilk cihazı seçiyoruz
      }
    } catch (error) {
      console.error('Mevcut cihazlar alınırken bir hata oluştu:', error);
    }
  };



  const movePhotoToUnmatched = async (photo) => {
    try {
      const updatedPhoto = {
        standid: selectedStandId,  // Fotoğrafı seçili stand'a taşımak için stand ID'yi güncelliyoruz
      };

      // Backend'e istek gönderip fotoğrafı belirtilmemiş fotoğraflara taşıyoruz
      await axios.put(`https://e.sakagrup.com/photo/${photo.id}/move-to-unmatched`, updatedPhoto);

      // UI'da belirtilmemiş görsellere taşıyoruz
      setUnmatchedPhotos((prevPhotos) => [...prevPhotos, { ...photo, standid: selectedStandId, categoryid: null }]);
      setCapturedPhotos((prevPhotos) => prevPhotos.filter((p) => p.id !== photo.id));
      setSelectedPhoto();

    } catch (error) {
      console.error('Fotoğraf belirtilmemiş görsellere taşınırken hata oluştu:', error);
    }
  };

  useEffect(() => {
    getAvailableDevices(); // Sayfa yüklendiğinde mevcut kameraları getir
  }, []);


  useEffect(() => {
    if (currentDeviceId) {
      startCamera(); // Yeni cihaz seçildiğinde kamerayı başlat
    }
  }, [currentDeviceId]); // currentDeviceId değiştiğinde kamerayı yeniden başlat


  const toggleCamera = () => {
    if (availableDevices.length > 1) {
      const newDeviceId = availableDevices.find(device => device.deviceId !== currentDeviceId).deviceId;

      // Kamera izni kontrolü
      stopCamera();
      setCurrentDeviceId(newDeviceId); // Yeni kamera ID'sini set et
      setIsFrontCamera(!isFrontCamera); // Kameranın ön/arka durumunu değiştir
    } else {
      console.log('Birden fazla kamera bulunamadı.');
    }
  };

  const stopCamera = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop()); // Her bir track'i durdur
      videoRef.current.srcObject = null; // Video kaynağını temizle
    }
    setCameraActive(false); // Kamera devre dışı
  };

  const startCamera = async () => {
    const permissionGranted = await requestCameraPermission();
    if (!permissionGranted) return;

    try {
      const constraints = {
        video: {
          facingMode: isFrontCamera ? 'user' : 'environment', // Ön veya arka kamera geçişi
        },
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;

        try {
          await videoRef.current.play();
          const videoTrack = stream.getVideoTracks()[0];

          // Eğer cihaz zoom destekliyorsa, max ve min zoom değerlerini alıyoruz
          const capabilities = videoTrack.getCapabilities();
          if (capabilities.zoom) {
            setMaxZoom(capabilities.zoom.max); // Maksimum zoom değerini set ediyoruz
            videoTrack.applyConstraints({ advanced: [{ zoom: zoomLevel }] });
          } else {
            console.warn("Zoom desteği bulunmuyor");
          }
        } catch (error) {
          console.error("Video oynatılamadı:", error);
        }
      }
    } catch (error) {
      console.error('Kamera başlatılamadı:', error);
    }
  };

  // Zoom seviyesini değiştirme fonksiyonu
  const handleZoomChange = async (e) => {
    const newZoomLevel = e.target.value;
    setZoomLevel(newZoomLevel);

    // Kamera zoom seviyesini güncelleme
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const videoTrack = stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities();
      if (capabilities.zoom) {
        await videoTrack.applyConstraints({ advanced: [{ zoom: newZoomLevel }] });
      } else {
        console.warn("Zoom desteği bulunmuyor");
      }
    }
  };

  const capturePhoto = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const video = videoRef.current;

    // Videonun orijinal genişlik ve yüksekliğini alın
    const originalWidth = video.videoWidth;
    const originalHeight = video.videoHeight;

    // Yüksek çözünürlük için, canvas boyutlarını orijinal video boyutuna göre ayarlayın
    canvas.width = originalWidth;  // Orijinal genişlikte kullanıyoruz
    canvas.height = originalHeight; // Orijinal yükseklikte kullanıyoruz

    // Video akışını canvas'a çiz
    context.drawImage(video, 0, 0, originalWidth, originalHeight);

    // Fotoğrafı PNG formatında alın (JPEG de kullanabilirsiniz)
    const photo = canvas.toDataURL('image/png', 1.0); // 1.0 kalite seviyesi

    const newPhoto = {
      id: Date.now(),
      url: photo,
      standid: selectedStandId || null, // Eğer stant seçili değilse null olur
      categoryid: null, // Kategori seçilmemiş
      isPlaceholder: true, // Placeholder olarak işaretliyoruz (gerçek görsel backend'den dönecek)
    };

    if (selectedStandId) {
      setUnmatchedPhotos((prevPhotos) => [...prevPhotos, newPhoto]);
    } else {
      setCapturedPhotos((prevPhotos) => [...prevPhotos, newPhoto]);
    }

    savePhotoToBackend(newPhoto);
  };


  const savePhotoToBackend = async (photo) => {
    try {
      const payload = {
        userid: userId,
        standid: photo.standid || null,
        categoryid: photo.categoryid || null,
        photo: photo.url.split(',')[1], // Base64 verisini gönderiyoruz
        report_type: 'fuarsonrasi',
      };

      const response = await axios.post('https://e.sakagrup.com/save-photo', payload);

      if (response.data.success) {
        const savedPhotoId = response.data.photoId;

        if (photo.standid) {
          // Stant seçiliyse belirtilmemiş fotoğrafları güncelle
          setUnmatchedPhotos((prevPhotos) =>
            prevPhotos.map((p) =>
              p.id === photo.id ? { ...p, id: savedPhotoId, isPlaceholder: false } : p
            )
          );
        } else {
          // Stant seçili değilse capturedPhotos'u güncelle
          setCapturedPhotos((prevPhotos) =>
            prevPhotos.map((p) =>
              p.id === photo.id ? { ...p, id: savedPhotoId, isPlaceholder: false } : p
            )
          );
        }

      }
    } catch (error) {
      console.error('Fotoğraf kaydedilirken bir hata oluştu:', error);
    }
  };


  const fetchUnmatchedPhotos = async (standId) => {
    try {
      const response = await axios.get(`https://e.sakagrup.com/stand/${standId}/unmatched-photos`);

      // Backend'den gelen fotoğraflar set ediliyor, isPlaceholder olmadan ekleniyor
      const backendPhotos = response.data.map((photo) => ({
        ...photo,
        isPlaceholder: false, // Backend'den gelenler placeholder değil
      }));

      setUnmatchedPhotos(backendPhotos); // State'e backend'den gelen tüm fotoğrafları set ediyoruz
    } catch (error) {
      console.error('Eşlenmemiş fotoğraflar getirilirken bir hata oluştu:', error);
    }
  };


  const clearSelectedStand = () => {
    setSelectedStandId(null);
    setReports(null);
    fetchStands();
  };

  const renderUnmatchedPhotosCard = () => (
    <Card style={{
      ...styles.unmatchedCard,
      boxShadow: selectedPhoto ? '0 0 10px 2px #0098dd' : '0 4px 8px rgba(0, 0, 0, 0.1)', cursor: selectedPhoto ? 'pointer' : 'auto',  // Seçili fotoğraf varsa card gölgesini değiştir
    }} onClick={() => movePhotoToUnmatched(selectedPhoto)}>
      <CardBody>
        <h4 style={styles.unmatchedCardTitle}>Eşlenmemiş Stant Fotoğrafları</h4>
        {unmatchedPhotos.length > 0 ? (
          <div style={styles.unmatchedPhotosContainer}>
            {renderUnmatchedPhotos()}
          </div>
        ) : (
          <p style={styles.noUnmatchedPhotosText}>Belirtilmemiş fotoğraf yok.</p>
        )}
      </CardBody>
    </Card>
  );

  const deletePhoto = async (photoId) => {
    try {
      const response = await axios.delete(`https://e.sakagrup.com/delete-photo/${photoId}`);
      if (response.data.success) {
        // Fotoğrafı "capturedPhotos" listesinden kaldır
        setCapturedPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== photoId));
        setUnmatchedPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== photoId));
        setSelectedPhoto(null); // Seçili fotoğrafı sıfırla
      }
    } catch (error) {
      console.error('Fotoğraf silinirken bir hata oluştu:', error);
    }
  };

  // Fotoğraf çekme fonksiyonu
  const capturePhotoForCategory = async (categoryId) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const video = videoRef.current;

    if (!video) {
      console.error('Video kaynağı bulunamadı.');
      return;
    }

    // Videonun orijinal genişlik ve yüksekliğini alın
    const originalWidth = video.videoWidth;
    const originalHeight = video.videoHeight;

    // Yüksek çözünürlük için, canvas boyutlarını orijinal video boyutuna göre ayarlayın
    canvas.width = originalWidth;
    canvas.height = originalHeight;

    // Video akışını canvas'a çiz
    context.drawImage(video, 0, 0, originalWidth, originalHeight);

    // Fotoğrafı PNG formatında alın
    const photo = canvas.toDataURL('image/png', 1.0);

    // Fotoğrafı backend'e kaydetme
    try {
      const payload = {
        userid: userId,
        standid: selectedStandId,  // Seçili stand ID
        categoryid: categoryId,    // Seçili kategori ID
        photo: photo.split(',')[1], // Base64 verisini gönderiyoruz
        report_type: 'fuarsonrasi',   // Rapor tipi
      };

      const response = await axios.post('https://e.sakagrup.com/save-photo', payload);
      if (response.data.success) {
        fetchStandReports(selectedStandId);
        console.log('Fotoğraf başarıyla kaydedildi!');
      }
    } catch (error) {
      console.error('Fotoğraf kaydedilirken bir hata oluştu:', error);
    }
  };

  const deleteStandFromPhoto = async (photoId) => {
    if (!photoId) {
      console.error('Fotoğraf ID eksik, stant ID silinemiyor.');
      return;
    }

    try {
      // Stant ID'sini null yapma isteği gönderiliyor
      await axios.put(`https://e.sakagrup.com/photo/${photoId}/clear-stand`, {
        standid: null, // Stant ID'yi null yaparak kaldırıyoruz
      });

      // Fotoğrafın stant ID'si silindikten sonra UI güncelleniyor
      setUnmatchedPhotos((prevPhotos) =>
        prevPhotos.map((photo) =>
          photo.id === photoId ? { ...photo, standid: null } : photo // Stant ID'sini null yap
        )
      );

      console.log('Fotoğrafın stant ID\'si başarıyla kaldırıldı.');
    } catch (error) {
      console.error('Stant ID silinirken bir hata oluştu:', error);
    }
  };

  const renderUnmatchedPhotos = () => (
    <div style={styles.unmatchedPhotosContainer}>
      {unmatchedPhotos && unmatchedPhotos.length > 0 ? (
        <div style={styles.unmatchedPhotos}>
          {unmatchedPhotos.map((photo) => {
            if (!photo) return null;

            const photoSrc = photo.url
              ? photo.url
              : photo.photopath
                ? `https://e.sakagrup.com/${photo.photopath}`
                : '';

            return (
              <div
                key={photo.id}
                style={{
                  ...styles.unmatchedPhotoWrapper,
                  boxShadow: selectedPhoto?.id === photo.id ? '0 0 10px 2px #0098dd' : '0 4px 8px rgba(0, 0, 0, 0.1)',
                  cursor: 'pointer',
                }}
                onClick={() => handlePhotoClick(photo)} // Fotoğraf tıklama olayını handlePhotoClick'e bağlıyoruz
              >
                <CardImg
                  src={photoSrc}
                  alt={`Unmatched ${photo.id}`}
                  style={{
                    ...styles.unmatchedPhoto,
                    opacity: photo.isPlaceholder ? 0.5 : 1,
                  }}
                />
                <div
                  style={styles.deleteButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    removePhotoFromCategory(photo, photo.categoryid);
                    deleteStandFromPhoto(photo.id);
                    if (selectedPhoto?.id === photo.id) {
                      setSelectedPhoto(null);
                    }
                  }}
                >
                  <i className="bx bx-x-circle" style={{ color: 'red', fontSize: '20px', cursor: 'pointer' }}></i>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p style={styles.noPhotosText}>Eşleşmemiş fotoğraf bulunamadı.</p>
      )}
    </div>
  );

  const renderCapturedPhotos = () => (
    <div style={styles.capturedPhotosContainer}>
      {capturedPhotos && capturedPhotos.length > 0 ? (
        <div style={styles.capturedPhotos}>
          {capturedPhotos.map((photo) => {
            if (!photo) return null;

            const photoSrc = photo.url ? photo.url : '';

            return (
              <div
                key={photo.id}
                style={{
                  ...styles.capturedPhotoWrapper,
                  boxShadow: selectedPhoto?.id === photo.id ? '0 0 10px 2px #0098dd' : '0 4px 8px rgba(0, 0, 0, 0.1)',
                  cursor: 'pointer',
                }}
                onClick={() => handlePhotoClick(photo)} // Fotoğraf tıklama olayını handlePhotoClick'e bağlıyoruz
              >
                <CardImg
                  src={photoSrc}

                  style={{
                    ...styles.capturedPhoto,
                    opacity: photo.isPlaceholder ? 0.5 : 1,
                  }}
                />
                <div
                  style={styles.deleteButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    deletePhoto(photo.id);
                    if (selectedPhoto?.id === photo.id) {
                      setSelectedPhoto(null);
                    }
                  }}
                >
                  <i className="bx bx-x-circle"></i>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p style={styles.noPhotosText}>Çekilmiş fotoğraf bulunamadı.</p>
      )}
    </div>
  );


  const checkPermissions = async () => {
    try {
      const permissions = await navigator.permissions.query({ name: 'camera' });
      if (permissions.state === 'denied') {
        alert("Kamera izni reddedildi. Lütfen tarayıcı ayarlarından kamera izni verin.");
        return false;
      }
      return true;
    } catch (error) {
      console.error("Kamera izin kontrolü sırasında hata:", error);
      return false;
    }
  };

  const filteredStands = stands.filter(stand => {
    return selectedSalon === '' || stand.salon === selectedSalon;
  });


  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // İzin verildiyse burada kamera akışını başlatabilirsiniz
      return true;
    } catch (error) {
      console.error('Kamera erişimi izni reddedildi:', error);
      return false;
    }
  };


  const fetchStands = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://e.sakagrup.com/stands`, {
        params: {
          limit: LIMIT,
          offset: (currentPage - 1) * LIMIT,
          search: searchQuery,
          salon: selectedSalon,  // Salon filtresini backend'e gönderiyoruz
        },
      });
      setStands(response.data);
      checkReports(response.data); // Standların rapor durumlarını da kontrol et
    } catch (error) {
      console.error('Standlar getirilemedi:', error);
    }
    setLoading(false);
  };


  const checkReports = async (standList) => {
    const reportStatus = {};
    for (const stand of standList) {
      try {
        const response = await axios.get(`https://e.sakagrup.com/stand/${stand.id}/reports`);
        reportStatus[stand.id] = {
          fuaroncesi: !!response.data.fuaroncesi,
          fuarsonrasi: !!response.data.fuarsonrasi,
        };
      } catch (error) {
        reportStatus[stand.id] = { fuaroncesi: false, fuarsonrasi: false };
      }
    }
    setReportExists(reportStatus);
  };

  const fetchStandReports = async (standId) => {
    setLoading(true);
    setReports(null);
    setCategoryPhotos({});
    setUnmatchedPhotos([]);

    try {
      const response = await axios.get(`https://e.sakagrup.com/stand/${standId}/reports`);
      setReports(response.data);
      setSelectedStandId(standId);
      await fetchUnmatchedPhotos(standId);
      await fetchCategoryPhotosForStand(standId);

      // Stand türünü alıyoruz
      const typeResponse = await axios.get(`https://e.sakagrup.com/stands/${standId}/type`);
      setSelectedStandType(typeResponse.data.type);  // Stand türünü kaydet

    } catch (error) {
      console.error('Stand raporları getirilemedi:', error);
      setSelectedStandType(null); // Eğer hata varsa tür yoktur
    }
    setLoading(false);
  };

  const renderCategoryIcons = (category, type) => {
    const isOpen = openIcons[`${category}_${type}`]; // İkonun açık olup olmadığını kontrol ediyoruz

    return (
      <div style={styles.iconContainer}>
        <img
          src={getIconForValue(reports?.[type]?.[category])}
          alt={reports?.[type]?.[category] || 'Seçim'}
          onClick={(e) => {
            e.stopPropagation();
            toggleIconOptions(category, type); // İkonları açıp kapamak için tıklama eventi
          }}
          style={{
            ...styles.icon,
            opacity: 1,
            display: 'block',
            margin: '0 auto',
            position: 'relative',
          }}
        />

        <p style={styles.iconLabel}>
          {reports?.[type]?.[category] === 'evet' ? 'Uygun / Evet' :
            reports?.[type]?.[category] === 'hayır' ? 'Uygun Değil / Hayır' :
              reports?.[type]?.[category] === 'yok' ? 'Mevcut Değil / Yok' :
                'İşlem Bekliyor'}
        </p>

        {/* Eğer simgeler açıksa diğer seçenekleri göster */}
        {isOpen && (
          <div style={styles.iconOptions}>
            <div style={styles.iconOptionWrapper}>
              <img
                src={Evet}
                alt="Evet"
                onClick={() => handleIconClick('evet', category, type)}
                style={{
                  ...styles.icon,
                  opacity: reports?.[type]?.[category] === 'evet' ? 1 : 0.4,
                  display: 'block',
                  margin: '0 auto',
                }}
              />
              <p style={styles.iconLabel}>Uygun / Evet</p>
            </div>

            <div style={styles.iconOptionWrapper}>
              <img
                src={Hayır}
                alt="Hayır"
                onClick={() => handleIconClick('hayır', category, type)}
                style={{
                  ...styles.icon,
                  opacity: reports?.[type]?.[category] === 'hayır' ? 1 : 0.4,
                  display: 'block',
                  margin: '0 auto',
                }}
              />
              <p style={styles.iconLabel}>Uygun Değil / Hayır</p>
            </div>

            <div style={styles.iconOptionWrapper}>
              <img
                src={Yok}
                alt="Yok"
                onClick={() => handleIconClick('yok', category, type)}
                style={{
                  ...styles.icon,
                  opacity: reports?.[type]?.[category] === 'yok' ? 1 : 0.4,
                  display: 'block',
                  margin: '0 auto',
                }}
              />
              <p style={styles.iconLabel}>Mevcut Değil / Yok</p>
            </div>

            <div style={styles.iconOptionWrapper}>
              <img
                src={Belirtilmedi}
                alt="Belirtilmedi"
                onClick={() => handleIconClick(null, category, type)}
                style={{
                  ...styles.icon,
                  opacity: reports?.[type]?.[category] === null ? 1 : 0.4,
                  display: 'block',
                  margin: '0 auto',
                }}
              />
              <p style={styles.iconLabel}>İşlem Bekliyor</p>
            </div>
          </div>
        )}

        <div style={styles.photoGridContainer}>
          {categoryPhotos[category.id]?.map((photo) => (
            <div key={photo.id} style={styles.photoWrapper}>
              <img
                src={`https://e.sakagrup.com/${photo.photopath}`}
                alt={`Category ${category.id} Photo ${photo.id}`}
                style={styles.photo}
              />
              {/* Çarpı butonunu sağ üst köşeye yerleştiriyoruz */}
              <i
                className="bx bx-x-circle"
                style={styles.deleteIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  removePhotoFromCategory(photo, category.id);
                }}
              ></i>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const fetchCategoryPhotosForStand = async (standId) => {
    const categories = [
      'stant_yapisi_ve_duvarlar',
      'platform',
      'mobilya_ve_techizat',
      'aydinlatma',
      'alinlik_ve_bas_ustu',
      'donatilmis_yapi',
      'tavan',
      'teshir_bilesenleri',
      'zemin',
      'grafikler_dekoratifler',
      'ana_fotograf' // Ana fotoğrafı da dahil ediyoruz (categoryId: 11)
    ];

    const photosData = {};

    for (let i = 0; i < categories.length; i++) {
      try {
        const response = await axios.get(`https://e.sakagrup.com/stand/${standId}/category/${i + 1}/photos`);
        photosData[i + 1] = response.data; // Gelen fotoğrafları kaydediyoruz
      } catch (error) {
        console.error(`Stand ${standId} için kategori ${i + 1} fotoğrafları getirilirken hata oluştu:`, error);
        photosData[i + 1] = []; // Hata durumunda boş array ile devam ediyoruz
      }
    }

    setCategoryPhotos(photosData);  // Kategorilere göre fotoğrafları kaydediyoruz
  };

  useEffect(() => {
    const fetchReportStatuses = async () => {
      const statuses = {};
      const statusess = {};
      for (const stand of stands) {
        const statusColor = await checkReportStatus(stand.id); // Rapor durumu kontrolü
        const statusColorr = await checkReportStatuss(stand.id); // Rapor durumu kontrolü
        statuses[stand.id] = statusColor; // Her stand için rengi kaydet
        statusess[stand.id] = statusColorr; // Her stand için rengi kaydet
      }
      setReportStatuses(statuses); // Rapor durumlarını state'e kaydet
      setReportStatusess(statusess); // Rapor durumlarını state'e kaydet
    };

    if (stands.length > 0) {
      fetchReportStatuses(); // reportType değiştiğinde tekrar çağır
    }
  }, [stands, reportType]); // reportType her değiştiğinde çalışır


  const handlePhotoClick = (photo) => {
    if (selectedPhoto?.id === photo.id) {
      // Eğer zaten seçili olan fotoğraf tekrar seçildiyse, seçimi kaldır
      setSelectedPhoto(null);
    } else {
      // Fotoğrafı seç
      setSelectedPhoto(photo);
    }
  };

  useEffect(() => {
    if (selectedStandId) {
      fetchStandReports(selectedStandId); // Stand raporlarını getirme
      fetchCategoryPhotosForStand(selectedStandId); // Kategorilere göre fotoğrafları yükleme
    }
  }, [selectedStandId]);



  useEffect(() => {
    checkPermissions();
    fetchStands();
    startCamera();
  }, [currentPage, searchQuery, selectedSalon]);

  const nextPage = () => setCurrentPage((prevPage) => prevPage + 1);
  const prevPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));

  const handleSearch = (e) => {
    e.preventDefault();  // Formun sayfayı yenilemesini engelle
    setSearchQuery(e.target.value);  // Search query'yi güncelle
  };

  const toggleFuaroncesi = () => {
    setIsFuaroncesiOpen(!isFuaroncesiOpen);
    setIsFuarsonrasiOpen(false);
  };

  const toggleFuarsonrasi = () => {
    setIsFuarsonrasiOpen(!isFuarsonrasiOpen);
    setIsFuaroncesiOpen(false);
  };

  const formatCategoryName = (categoryKey) => {
    return categoryNames[categoryKey] || categoryKey; // Eğer tanımlı değilse orijinal ismi döndürür.
  };


  const categoryNames = {
    'stant_yapisi_ve_duvarlar': 'Stant Yapısı ve Duvarlar',
    'platform': 'Platform',
    'mobilya_ve_techizat': 'Mobilya ve Teçhizat',
    'aydinlatma': 'Aydınlatma',
    'alinlik_ve_bas_ustu': 'Alınlık ve Baş Üstü',
    'donatilmis_yapi': 'Donatilmis',
    'tavan': 'Tavan',
    'teshir_bilesenleri': 'Teşhir Bileşenleri',
    'zemin': 'Zemin',
    'grafikler_dekoratifler': 'Grafikler ve Dekoratifler',
    'ana_fotograf': 'Ana Fotoğraf'
  };

  const categoryNamess = {
    'stant_yapisi_ve_duvarlar': 'Duvarlar',
    'platform': 'Platform',
    'mobilya_ve_techizat': 'Mobilya',
    'aydinlatma': 'Aydınlatma',
    'alinlik_ve_bas_ustu': 'Alınlık',
    'donatilmis_yapi': 'Donatilmis',
    'tavan': 'Tavan',
    'teshir_bilesenleri': 'Vitrin',
    'zemin': 'Zemin',
    'grafikler_dekoratifler': 'Grafikler',
    'ana_fotograf': 'Ana Fotoğraf'
  };

  const getIconForValue = (value) => {
    switch (value) {
      case 'evet':
        return Evet;
      case 'hayır':
        return Hayır;
      case 'yok':
        return Yok;
      case 'belirtilmedi':
      default:
        return Belirtilmedi;
    }
  };

  const toggleIconOptions = (category, type) => {
    setOpenIcons((prev) => ({
      ...prev,
      [`${category}_${type}`]: !prev[`${category}_${type}`],  // Her kategori ve rapor türü için eşsiz bir key kullanıyoruz
    }));
  };

  const handleIconClick = async (value, category, type) => {
    try {
      const payload = {
        stand_id: selectedStandId,
        category,
        type,
        value,
      };

      // Backend'de raporu güncelliyoruz
      await axios.put(`https://e.sakagrup.com/reports/${type}/${selectedStandId}`, payload);

      // Raporu frontend'de güncelliyoruz
      setReports((prevReports) => ({
        ...prevReports,
        [type]: {
          ...prevReports[type],
          [category]: value,
        },
      }));

      // İkon seçeneklerini kapatıyoruz
      toggleIconOptions(category, type);

      console.log(`${category} için ${value} kaydedildi.`);
    } catch (error) {
      console.error('Rapor kaydedilirken bir hata oluştu:', error);
    }
  };

  const categories = [
    { id: 1, name: 'stant_yapisi_ve_duvarlar' },
    { id: 2, name: 'platform' },
    { id: 3, name: 'mobilya_ve_techizat' },
    { id: 4, name: 'aydinlatma' },
    { id: 5, name: 'alinlik_ve_bas_ustu' },
    { id: 6, name: 'donatilmis_yapi' },
    { id: 7, name: 'tavan' },
    { id: 8, name: 'teshir_bilesenleri' },
    { id: 9, name: 'zemin' },
    { id: 10, name: 'grafikler_dekoratifler' },
    { id: 11, name: 'ana_fotograf' }
  ];

  const handleCategoryClick = async (categoryId) => {
    // Eğer seçilen fotoğraf yoksa işlem yapmadan çık
    if (!selectedPhoto) {
      if (openCategory === categoryId) {
        setOpenCategory(null);
      } else {
        await updateCategoryPhotos(categoryId);
        setOpenCategory(categoryId);
      }
      return;
    }

    try {
      // Seçili fotoğraf varsa ve henüz kategori atanmadıysa
      if (!selectedPhoto.categoryid) {
        const updatedPhoto = {
          ...selectedPhoto,
          categoryid: categoryId,  // İlgili kategoriye göre categoryid atanır
          standid: selectedStandId,  // Fotoğrafın stant ID'sini de güncelliyoruz
          report_type: reportType,  // Rapor tipi (fuaroncesi veya fuarsonrasi)
        };

        // Fotoğrafı backend'e güncelleyip kategoriye ekliyoruz
        await axios.put(`https://e.sakagrup.com/photo/${selectedPhoto.id}/category`, updatedPhoto);

        // UI güncellemesi
        updateCategoryPhotos(categoryId);

        setCapturedPhotos((prevPhotos) =>
          prevPhotos.filter((photo) => photo.id !== selectedPhoto.id)
        );
        setUnmatchedPhotos((prevPhotos) =>
          prevPhotos.filter((photo) => photo.id !== selectedPhoto.id)
        );
        setSelectedPhoto(null); // Seçilen fotoğrafı sıfırla
      } else {
        if (openCategory === categoryId) {
          setOpenCategory(null);
        } else {
          await updateCategoryPhotos(categoryId);
          setOpenCategory(categoryId);
        }
      }
    } catch (error) {
      console.error('Fotoğraf kategoriye eklenirken hata oluştu:', error);
    }
  };


  const renderCategoryPhotos = (categoryId) => {
    const photos = categoryPhotos[categoryId] || [];

    const fuarOncesiPhotos = photos.filter(photo => photo.report_type === 'fuaroncesi');
    const fuarSonrasiPhotos = photos.filter(photo => photo.report_type === 'fuarsonrasi');

    if (photos.length === 0) {
      return <p style={styles.noPhotosText}>Bu kategoride henüz fotoğraf yok.</p>;
    }

    return (
      <div style={styles.photosContainer}>
        {fuarOncesiPhotos.length > 0 && (
          <>
            <h5>Fuar Öncesi</h5>
            <div style={styles.photosRow}>
              {fuarOncesiPhotos.map((photo) => (
                <div key={photo.id} style={styles.photoWrapper}>
                  <CardImg
                    src={`https://e.sakagrup.com/${photo.photopath}`}
                    alt={`Category ${categoryId} Photo ${photo.id}`}
                    style={styles.photo}
                    onClick={() => handleImageClick(`https://e.sakagrup.com/${photo.photopath}`)} // Görsele tıklanınca modal açılır
                  />
                  <div style={styles.fuarOncesiBadge}>Fuar Öncesi</div>
                  <div
                    style={styles.deleteButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      removePhotoFromCategory(photo, categoryId);
                    }}
                  >
                    <i className="bx bx-x-circle" style={{ color: 'red', fontSize: '20px', cursor: 'pointer' }}></i>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        {fuarSonrasiPhotos.length > 0 && (
          <>
            <h5>Fuar Sonrası</h5>
            <div style={styles.photosRow}>
              {fuarSonrasiPhotos.map((photo) => (
                <div key={photo.id} style={styles.photoWrapper}>
                  <CardImg
                    src={`https://e.sakagrup.com/${photo.photopath}`}
                    alt={`Category ${categoryId} Photo ${photo.id}`}
                    style={styles.photo}
                    onClick={() => handleImageClick(`https://e.sakagrup.com/${photo.photopath}`)} // Görsele tıklanınca modal açılır
                  />
                  <div style={styles.fuarSonrasiBadge}>Fuar Sonrası</div>
                  <div
                    style={styles.deleteButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      removePhotoFromCategory(photo, categoryId);
                    }}
                  >
                    <i className="bx bx-x-circle" style={{ color: 'red', fontSize: '20px', cursor: 'pointer' }}></i>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  const removePhotoFromCategory = async (photo, categoryId) => {
    try {
      await axios.put(`https://e.sakagrup.com/photo/${photo.id}/remove-category`, {
        categoryid: null,
        standid: photo.standid || selectedStandId,
      });

      // Kategoriden fotoğrafı çıkar ve sayıyı güncelle
      fetchStandReports(photo.standid)
      updateCategoryPhotos(categoryId);

      setUnmatchedPhotos((prevPhotos) => {
        if (!prevPhotos || !Array.isArray(prevPhotos)) return [];

        // Fotoğrafı "unmatchedPhotos" listesinden kaldır
        return prevPhotos.filter((p) => p.id !== photo.id);
      });

      setCategoryPhotos((prevPhotos) => {
        if (!prevPhotos || !prevPhotos[categoryId] || !Array.isArray(prevPhotos[categoryId])) return prevPhotos;

        // Fotoğrafı "categoryPhotos" listesinden kaldır
        return {
          ...prevPhotos,
          [categoryId]: prevPhotos[categoryId].filter((p) => p.id !== photo.id), // Fotoğrafı kategoriden kaldır
        };
      });

      setSelectedPhoto(null); // Seçili fotoğrafı sıfırla, böylece kopyalanmış görünmez
    } catch (error) {
      console.error('Fotoğraf kategoriden çıkarılırken hata oluştu:', error);
    }
  };

  const updateCategoryPhotos = async (categoryId) => {
    try {
      const response = await axios.get(`https://e.sakagrup.com/stand/${selectedStandId}/category/${categoryId}/photos`);
      setCategoryPhotos((prevPhotos) => ({
        ...prevPhotos,
        [categoryId]: response.data,  // Yalnızca ilgili kategoriyi güncelliyoruz
      }));
    } catch (error) {
      console.error('Kategori fotoğrafları güncellenirken hata oluştu:', error);
    }
  };

  const checkReportStatus = async (standId) => {
    try {
      const response = await axios.get(`https://e.sakagrup.com/stand/${standId}/status`, {
        params: { reportType },  // Backend'e hangi rapor tipini istediğimizi bildiriyoruz
      });
      const { badgeColor } = response.data;

      return badgeColor;  // Badge rengini geri döndür
    } catch (error) {
      console.error('Durum kontrolü yapılırken hata oluştu:', error);
      return 'gray';  // Hata durumunda gri döndürüyoruz
    }
  };

  const checkReportStatuss = async (standId) => {
    try {
      const response = await axios.get(`https://e.sakagrup.com/stand/${standId}/statuss`, {
        params: { reportType },  // Backend'e hangi rapor tipini istediğimizi bildiriyoruz
      });
      const { badgeColor } = response.data;

      return badgeColor;  // Badge rengini geri döndür
    } catch (error) {
      console.error('Durum kontrolü yapılırken hata oluştu:', error);
      return 'gray';  // Hata durumunda gri döndürüyoruz
    }
  };


  const renderReportCard = (type, label, isOpen, toggle) => {
    const standIndex = stands.findIndex((stand) => stand.id === selectedStandId); // Mevcut standın index'ini al

    const handlePreviousReport = () => {
      if (standIndex > 0) {
        fetchStandReports(stands[standIndex - 1].id); // Önceki standın raporunu aç
      }
    };

    const handleNextReport = () => {
      if (standIndex < stands.length - 1) {
        fetchStandReports(stands[standIndex + 1].id); // Sonraki standın raporunu aç
      }
    };

    return (
      <Card style={styles.reportCard}>
        <CardBody>
          <div style={styles.reportHeader}>
            <h4 style={styles.reportCardHeader}>{label}</h4>

            <i
              className={`bx ${isOpen ? 'bx-chevron-up' : 'bx-chevron-down'}`}
              style={styles.chevronIcon}
              onClick={toggle}
            ></i>
          </div>
          <Collapse isOpen={isOpen}>
            <Row>
              {categories.map((category) => {
                // Eğer donatılmış yapı ise bu kategoriyi atla
                if (category.id === 11) {
                  return null; // Donatılmış yapıyı tamamen es geçiyoruz
                }

                // Fuar öncesi ve fuar sonrası fotoğraf sayıları
                const fuarOncesiCount = categoryPhotos[category.id]?.filter(photo => photo.report_type === 'fuaroncesi').length || 0;
                const fuarSonrasiCount = categoryPhotos[category.id]?.filter(photo => photo.report_type === 'fuarsonrasi').length || 0;

                return (
                  <Col xs="12" md="12" lg="12" key={category.id} style={styles.categoryCol}>
                    <Card
                      style={{
                        ...styles.innerCard,
                        boxShadow: selectedPhoto && !selectedPhoto.categoryid &&
                          (reports?.[type]?.[category.name] === 'evet' ||
                            reports?.[type]?.[category.name] === 'hayır' ||
                            reports?.[type]?.[category.name] === 'yok')
                          ? '0 0 10px 2px #0098dd'
                          : '0 4px 8px rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer',
                        position: 'relative',
                      }}
                      onClick={() => handleCategoryClick(category.id)}
                    >
                      <CardBody>
                        <h5 style={styles.categoryTitle}>{formatCategoryName(category.name)}</h5>

                        <div style={styles.iconContainer}>
                          <div style={styles.imageWrapper}>
                            {renderCategoryIcons(category.name, type)}

                            {/* Fotoğraf sayaçlarını ayrı ayrı göster */}
                            <div style={styles.photoCountBadgeContainer}>
                              {/* Fuar öncesi fotoğraf sayısı kırmızı */}
                              {fuarOncesiCount > 0 && (
                                <div style={{ ...styles.photoCountBadge, backgroundColor: 'red', top: '-5px', right: '20px' }}>
                                  {fuarOncesiCount}
                                </div>
                              )}

                              {/* Fuar sonrası fotoğraf sayısı mavi */}
                              {fuarSonrasiCount > 0 && (
                                <div style={{ ...styles.photoCountBadge, backgroundColor: 'blue', top: '-5px', right: '-5px' }}>
                                  {fuarSonrasiCount}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Kategori açılırsa fotoğrafları göster */}
                        <i
                          className={`bx ${openCategory === category.id ? 'bx-chevron-up' : 'bx-chevron-down'}`}
                          style={styles.chevronIcon}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCategoryClick(category.id);
                          }}
                        ></i>

                        {/* Kategori açık ise fotoğrafları render et */}
                        {openCategory === category.id && renderCategoryPhotos(category.id)}
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Collapse>
        </CardBody>
      </Card>
    );
  };

  // Ana fotoğrafı yüklemek için fonksiyon
  const uploadMainPhoto = async () => {
    if (!selectedPhoto) {
      console.error("Seçili fotoğraf yok.");
      return;
    }

    try {
      const updatedPhoto = {
        ...selectedPhoto,
        categoryid: 11,  // Ana fotoğrafın categoryid'si 11 olarak ayarlanıyor
        standid: selectedStandId,  // Fotoğrafın stand ID'sini de güncelliyoruz
        report_type: reportType,  // Rapor tipi (fuaroncesi veya fuarsonrasi)
      };

      // Backend'e fotoğrafı ana fotoğraf olarak yükle
      await axios.put(`https://e.sakagrup.com/photo/${selectedPhoto.id}/category`, updatedPhoto);

      // UI güncellemesi
      updateCategoryPhotos(11); // Ana fotoğraf kategorisini güncelle
      setSelectedPhoto(null); // Seçilen fotoğrafı sıfırla
    } catch (error) {
      console.error("Ana fotoğraf yüklenirken hata oluştu:", error);
    }
  };

  // Ana fotoğraf yükleme butonu
  {
    selectedPhoto && (
      <Button color="primary" onClick={uploadMainPhoto} className="mt-3">
        Ana Fotoğraf Olarak Yükle
      </Button>
    )
  }

  const rotatePhoto = (photoId) => {
    setCategoryPhotos((prevPhotos) => {
      // Her fotoğrafın rotation değerini 90 derece arttırıyoruz
      const updatedPhotos = { ...prevPhotos };
      updatedPhotos[11] = updatedPhotos[11].map((photo) => {
        if (photo.id === photoId) {
          return {
            ...photo,
            rotation: (photo.rotation || 0) + 90, // Eğer bir rotation yoksa 0 başlar, her tıklamada 90 derece arttırır
          };
        }
        return photo;
      });
      return updatedPhotos;
    });
  };

  const rotatePhotoLeft = (photoId) => {
    setCategoryPhotos((prevPhotos) => {
      // Her fotoğrafın rotation değerini 90 derece azaltıyoruz
      const updatedPhotos = { ...prevPhotos };
      updatedPhotos[11] = updatedPhotos[11].map((photo) => {
        if (photo.id === photoId) {
          return {
            ...photo,
            rotation: (photo.rotation || 0) - 90, // Eğer bir rotation yoksa 0 başlar, her tıklamada 90 derece azaltır
          };
        }
        return photo;
      });
      return updatedPhotos;
    });
  };

  const renderMainPhotoCard = (type) => {
    const standIndex = stands.findIndex((stand) => stand.id === selectedStandId); // Mevcut standın index'ini al

    const handlePreviousReport = () => {
      if (standIndex > 0) {
        fetchStandReports(stands[standIndex - 1].id); // Önceki standın raporunu aç
      }
    };

    const handleNextReport = () => {
      if (standIndex < stands.length - 1) {
        fetchStandReports(stands[standIndex + 1].id); // Sonraki standın raporunu aç
      }
    };

    return (
      <Card
        style={{
          cursor: selectedPhoto && selectedStandType ? 'pointer' : 'auto',
          position: 'relative',
          boxShadow: selectedPhoto && selectedStandType ? '0 0 10px 2px #0098dd' : '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
        onClick={async () => {
          if (selectedPhoto && selectedStandType) {
            try {
              const updatedPhoto = {
                ...selectedPhoto,
                categoryid: 11,  // Ana Fotoğraf kategorisi
                standid: selectedStandId,
                report_type: reportType,
              };

              await axios.put(`https://e.sakagrup.com/photo/${selectedPhoto.id}/category`, updatedPhoto);
              updateCategoryPhotos(11);
              setSelectedPhoto(null);
            } catch (error) {
              console.error('Fotoğraf Ana Fotoğraf olarak yüklenirken hata oluştu:', error);
            }
          }
        }}
      >
        {/* Sol üst köşeye eklenen ikon (Önceki stand raporu) */}
        <i
          className="bx bx-chevron-left"
          style={{ ...styles.chevronIcon, position: 'absolute', left: '10px', top: '10px', cursor: 'pointer' }}
          onClick={handlePreviousReport}
        ></i>

        {/* Sağ üst köşeye eklenen ikon (Sonraki stand raporu) */}
        <i
          className="bx bx-chevron-right"
          style={{ ...styles.chevronIcon, position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
          onClick={handleNextReport}
        ></i>

        {/* Stant Kodu ve İsmi Ortada */}
        <div style={{ textAlign: 'center', marginBottom: '15px' }}>
          <p style={styles.standTitle}>
            {stands.find(stand => stand.id === selectedStandId)?.kod || 'Bilinmiyor'} <strong> - </strong> {stands.find(stand => stand.id === selectedStandId)?.isim || 'Bilinmiyor'}
          </p>
        </div>

        <CardBody style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {/* Stant Türü Dropdown */}
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="standTypeDropdown">Stant Türü Seç:</label>
            <Input
              type="select"
              value={selectedStandType || ''}
              onChange={async (e) => {
                const newType = e.target.value;
                setSelectedStandType(newType);

                try {
                  await axios.put(`https://e.sakagrup.com/stands/${selectedStandId}/type`, { type: newType });
                } catch (error) {
                  console.error('Stand türü güncellenirken hata oluştu:', error);
                }
              }}
            >
              <option value="" disabled>Tür Seçin</option>
              <option value="Ahşap">Ahşap</option>
              <option value="Modüler">Modüler</option>
              <option value="Paket">Paket</option>
            </Input>
          </div>

          <div style={{ overflowX: 'auto', display: 'flex', maxWidth: '100%', marginTop: '10px' }}>
            <div style={{ display: 'inline-flex', gap: '10px' }}>
              {categoryPhotos[11]?.length > 0 ? (
                categoryPhotos[11].map((photo) => (
                  <div key={photo.id} style={{ position: 'relative' }}>
                    <CardImg
                      src={`https://e.sakagrup.com/${photo.photopath}`}
                      alt={`Ana Fotoğraf ${photo.id}`}
                      style={{
                        width: '300px',
                        height: '300px',
                        objectFit: 'cover',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        margin: '0 auto',
                        transform: `rotate(${photo.rotation || 0}deg)`, // Rotasyon değerini buradan alıyoruz
                        transition: 'transform 0.3s ease-in-out',  // Dönüş animasyonu
                      }}
                      onClick={() => handleImageClick(`https://e.sakagrup.com/${photo.photopath}`)}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        color: 'red',
                        fontSize: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        removePhotoFromCategory(photo, 11);  // Ana fotoğraf kategorisinden çıkarma işlemi
                      }}
                    >
                      <i className="bx bx-x-circle"></i>
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '5px',
                        left: '5px',
                        color: 'blue',
                        fontSize: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        rotatePhoto(photo.id);  // Fotoğrafı sağa döndürme işlemi
                      }}
                    >
                      <i className="bx bx-rotate-right"></i>
                    </div>

                    {/* Sağ alt köşedeki sola döndürme ikonu */}
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '5px',
                        right: '5px',
                        color: 'blue',
                        fontSize: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        rotatePhotoLeft(photo.id);  // Fotoğrafı sola döndürme işlemi
                      }}
                    >
                      <i className="bx bx-rotate-left"></i>  {/* Sola döndürme ikonu */}
                    </div>
                  </div>
                ))
              ) : (
                <p style={{ fontSize: '18px', fontStyle: 'italic', color: 'gray' }}>Ana Fotoğraf yüklenmedi.</p>
              )}
            </div>
          </div>

          {!selectedStandType && (
            <p style={{ color: 'red', marginTop: '10px' }}>Stant türü seçilmeden ana fotoğraf yüklenemez.</p>
          )}
        </CardBody>
      </Card>
    );
  };


  return (
    <div className="page-content">
      <Container fluid>
        <div style={styles.cameraSection}>
          {cameraError ? (
            <p style={styles.textDanger}>{cameraError}</p>
          ) : (
            <div style={styles.cameraContainer}>
              {/* Kamera video akışı */}
              <div style={{ position: 'relative' }}>
                <video ref={videoRef} autoPlay muted playsInline style={styles.cameraVideo} />

                {/* Kamera aç/kapat butonu - video'nun sağ üst köşesine yerleştirildi */}
                <Button
                  color={cameraActive ? 'danger' : 'primary'}
                  onClick={cameraActive ? stopCamera : () => startCamera(currentDeviceId)}
                  style={styles.cameraToggleButton} // Sağ üst köşeye yerleştirilmiş stil
                >
                  <i className={cameraActive ? 'bx bx-camera-off' : 'bx bx-camera'} style={styles.iconStyle}></i>
                </Button>
              </div>

              {/* Kamera geçiş ve fotoğraf çekme butonları */}
              <div className="mt-2" style={styles.buttonContainer}>
                <Button color="success" onClick={capturePhoto}>
                  <i className="bx bx-camera" style={styles.iconStyle}></i>
                </Button>
                <Button
                  color="warning"
                  onClick={toggleCamera}
                  disabled={availableDevices.length <= 1}
                >
                  <i className="bx bx-refresh" style={styles.iconStyle}></i> {/* Kamera geçişi */}
                </Button>
              </div>

              {/* Zoom kontrolü - video'nun altında yerleştirildi */}
              <div style={styles.zoomControl}>
                <Input
                  type="range"
                  id="zoomRange"
                  min="1" // Minimum zoom değeri
                  max="5" // Cihazın desteklediği maksimum zoom değeri
                  step="0.1"
                  value={zoomLevel}
                  onChange={handleZoomChange}
                />
              </div>

              {/* Kategori butonları - zoom kontrolünün altında dikey yerleştirildi */}
              {selectedStandId && (
                <div style={styles.categoryButtonsGrid}>
                  {categories.map((category, index) => {
                    // Donatılmış yapı kategorisini gizlemek için kontrol ekliyoruz
                    if (category.name === 'donatilmis_yapi') {
                      return null; // Donatılmış yapı butonunu render etmiyoruz
                    }

                    const photoCount = categoryPhotos[category.id]?.filter(photo => photo.report_type === 'fuarsonrasi')?.length || 0;
                    const photoCountt = categoryPhotos[category.id]?.filter(photo => photo.report_type === 'fuaroncesi')?.length || 0;

                    return (
                      <div
                        key={category.id}
                        style={{
                          gridColumn: category.name === 'ana_fotograf' ? 'span 3' : 'span 1',
                          position: 'relative',
                        }} // Ana fotoğraf için geniş alan
                      >
                        <Button
                          color="primary"
                          onClick={() => capturePhotoForCategory(category.id)}
                          style={styles.categoryButton}
                        >
                          {categoryNamess[category.name]} {/* Kategori ismini buton üzerinde göster */}
                        </Button>
                        {/* Fotoğraf sayacı */}
                        {photoCount > 0 && (
                          <div style={styles.photoCountBadgee}>
                            {photoCount}
                          </div>
                        )}
                        {photoCountt > 0 && (
                          <div style={styles.photoCountBadgeee}>
                            {photoCountt}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}


              {/* Hata mesajları */}
              {cameraError && <p style={styles.textDanger}>{cameraError}</p>}
              <div>{renderCapturedPhotos()}</div>
            </div>
          )}
        </div>


        {!selectedStandId && (
          <Row className="mb-4 d-flex mt-4">
            <Col md="12" style={{ justifyContent: 'space-around' }} className="d-flex">
              <Button
                color={reportType === 'fuaroncesi' ? 'primary' : 'light'}
                onClick={() => {
                  setReportType('fuaroncesi');
                  setIsFuaroncesiOpen(true);
                  setIsFuarsonrasiOpen(false);
                }}
              >
                Fuar Öncesi
              </Button>
              <Button
                disabled
                color={reportType === 'fuarsonrasi' ? 'primary' : 'light'}
                onClick={() => {
                  setReportType('fuarsonrasi');
                  setIsFuarsonrasiOpen(true);
                  setIsFuaroncesiOpen(false);
                }}
              >
                Fuar Sonrası
              </Button>
            </Col>
          </Row>
        )}


        {selectedStandId && (
          <Card style={{ position: 'relative', justifyContent: 'end', justifyItems: 'end', alignContent: 'center', alignItems: 'end', backgroundColor: 'transparent', boxShadow: 'none' }}>
            <Col><Button color='danger' onClick={clearSelectedStand}><i className="bx bx-list-ul" style={styles.iconStyle}></i></Button></Col>
          </Card>
        )}



        {reports && selectedStandId && (
          <>
            {renderMainPhotoCard('ana_fotograf')}
            {renderUnmatchedPhotosCard()} {/* Belirtilmemiş fotoğraflar kartını rapor kartlarının üstüne ekledik */}

            {reportType === 'fuarsonrasi' && (
              <>
                {renderReportCard('fuarsonrasi', 'Fuar Sonrası Raporu', isFuarsonrasiOpen, toggleFuarsonrasi)}
              </>
            )}
            {reportType === 'fuaroncesi' && (
              <>
                {renderReportCard('fuaroncesi', 'Fuar Öncesi Raporu', true, toggleFuaroncesi)}
              </>
            )}

            {/* Ana Fotoğraf Kartı */}
          </>
        )}


        {/* Eğer selectedStandId yoksa, table ve search bar'ı gösteriyoruz */}
        {!selectedStandId && (
          <>
            {/* Search Bar */}
            <Row style={{ justifyContent: 'center', marginBottom: '20px' }}>
              <Col md="4">
                <Input
                  type="text"
                  placeholder="Stant ismi veya kodu ile ara"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </Col>
              <Col md="4">
                <Input
                  type="select"
                  value={selectedSalon}
                  onChange={(e) => setSelectedSalon(e.target.value)}
                >
                  <option value="">Tüm Salonlar</option>
                  <option value="4">Salon 4</option>
                  <option value="5">Salon 5</option>
                  <option value="6">Salon 6</option>
                  <option value="7">Salon 7</option>
                  <option value="8">Salon 8</option>
                  <option value="Fuaye">Fuaye</option>
                  <option value="X">Salon X</option>
                </Input>
              </Col>
              <Col md="2">
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle>
                    Gösterim: {LIMIT}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleLimitChange(1)}>1</DropdownItem>
                    <DropdownItem onClick={() => handleLimitChange(10)}>10</DropdownItem>
                    <DropdownItem onClick={() => handleLimitChange(25)}>25</DropdownItem>
                    <DropdownItem onClick={() => handleLimitChange(50)}>50</DropdownItem>
                    <DropdownItem onClick={() => handleLimitChange(100)}>100</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
            <Table bordered responsive className="mt-4 stand-table">
              <thead>
                <tr>
                  <th>Kod</th>
                  <th>Salon</th>
                  <th>İsim</th>
                  <th>Fuar Öncesi</th>
                  <th>Fuar Sonrası</th>
                  <th>Tür</th> {/* Yeni Tür sütunu */}
                </tr>
              </thead>
              <tbody>
                {stands.map((stand) => (
                  <tr key={stand.id} onClick={() => fetchStandReports(stand.id)} style={styles.clickableRow}>
                    <td>{stand.kod}</td>
                    <td>{stand.salon}</td>
                    <td>{stand.isim}</td>
                    <td>
                      <span
                        style={{
                          backgroundColor: reportStatuses[stand.id] || 'gray',  // Backend'den gelen rengi kullanıyoruz
                          color: 'white',
                          borderRadius: '12px',
                          padding: '6px 10px',
                          display: 'inline-flex',
                          alignItems: 'center',
                        }}
                      >
                        <i className="bx bx-store" style={{ fontSize: '18px' }}></i>
                      </span>
                    </td>
                    <td>
                      <span
                        style={{
                          backgroundColor: reportStatusess[stand.id] || 'gray',  // Backend'den gelen rengi kullanıyoruz
                          color: 'white',
                          borderRadius: '12px',
                          padding: '6px 10px',
                          display: 'inline-flex',
                          alignItems: 'center',
                        }}
                      >
                        <i className="bx bx-store" style={{ fontSize: '18px' }}></i>
                      </span>
                    </td>
                    <td>
                      {stand.type === 'Ah?ap' ? 'Ahşap' : stand.type || 'Belirtilmedi'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* Sayfa Geçiş Butonları */}
            <Row className="justify-content-around align-items-center mb-3">
              <Col xs="auto">
                <Button color="secondary" onClick={prevPage} disabled={currentPage === 1}>
                  Geri
                </Button>
              </Col>
              <Col xs="auto">
                <Button color="secondary" onClick={nextPage} disabled={stands.length < LIMIT}>
                  İleri
                </Button>
              </Col>
            </Row>
          </>
        )}

        {loading && <p>Veriler yükleniyor...</p>}

        <Modal isOpen={isModalOpen} toggle={toggleModal} centered>
          <ModalBody>
            {modalImage && (
              <img src={modalImage} alt="Selected" style={{ width: '100%', height: 'auto' }} />
            )}
            <Button color="secondary" onClick={toggleModal} style={{ marginTop: '10px' }}>
              Kapat
            </Button>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};

const styles = {
  standHeader: {
    textAlign: 'center',
    marginBottom: '15px',
  },
  categoryButton: {
    width: '100%', // Tam genişlik
  },
  standTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '0 auto',
  },
  mainPhoto: {
    width: '300px',
    height: '300px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '0 auto',
  },
  photosRow: {
    display: 'inline-block', // Görsellerin yan yana sıralanması
    whiteSpace: 'nowrap', // Satır atlamasını engeller
    overflowX: 'auto', // Yatay scroll bar eklenir
    width: '100%', // Tam genişlik
    paddingBottom: '10px', // Alt kısımda biraz boşluk
  },
  noMainPhotoText: {
    fontSize: '18px',
    fontStyle: 'italic',
    color: 'gray',
    textAlign: 'center',
  },
  iconLabel: {
    textAlign: 'center',
    marginTop: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  standInfoCard2: {
    marginBottom: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '0px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  standInfoCardd: {
    marginBottom: '20px',
    justifyContent: 'end',
    alignItems: 'end',
    paddingBottom: '0px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  standInfoTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  mainPhoto: {
    width: '150px',
    height: '150px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  categoryButtonsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)', // 3 sütun
    gap: '10px',
    marginTop: '20px', // Zoom kontrolünün altında
  },
  noMainPhotoText: {
    fontSize: '16px',
    fontStyle: 'italic',
    color: 'gray',
  },
  clearButton: {
    bottom: '20px',
    right: '150px',
    cursor: 'pointer',
    fontSize: '5px',
    padding: '5px',
    backgroundColor: 'rgba(255, 0, 0, 0.7)',
    borderRadius: '20%',
    color: 'white',
    zIndex: 1000,  // Üstte gözükmesi için zIndex veriyoruz
  },
  clearButtonIcon: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  deleteButton: {
    position: 'absolute',
    top: '5px', // Sağ üst köşeye yerleştiriyoruz
    right: '5px',
    color: 'red',
    fontSize: '20px',
    cursor: 'pointer',
    zIndex: 1, // Çarpı butonunun her zaman üstte olmasını sağlıyoruz
  },
  cameraSection: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  cameraContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  cameraToggleButton: {
    position: 'absolute',
    top: '0px',
    right: '34px',
    zIndex: 10,
  },
  cameraVideo: {
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
    maxHeight: '200px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '0 auto',
  },
  photoGridContainer: {
    display: 'flex',
    flexWrap: 'wrap', // Görselleri yan yana ve gerektiğinde alt alta yerleştirir
    gap: '10px', // Görseller arasındaki boşluk
    justifyContent: 'flex-start', // Görsellerin sola yaslanmasını sağlar
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '10px',
  },
  iconStyle: {
    fontSize: '24px',
  },
  capturedPhotosContainer: {
    width: '100%',
    overflowX: 'auto',
    maxWidth: '400px',
  },
  unmatchedPhotosContainer: {
    display: 'flex',
    justifyContent: 'center', // Yatayda ortalıyoruz
    overflowX: 'auto', // Yatay scroll
    padding: '10px',
    whiteSpace: 'nowrap',
  },
  unmatchedPhotos: {
    display: 'inline-flex',
    gap: '10px',
    justifyContent: 'center', // Yatayda ortalıyoruz
  },
  photoCountBadgeContainer: {
    display: 'flex', // Her iki sayacı yan yana göstermek için flex layout
    gap: '8px', // Sayaçlar arasına boşluk ekler
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  unmatchedPhotoWrapper: {
    position: 'relative',
    display: 'inline-block',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Varsayılan gölge
    transition: 'box-shadow 0.3s ease', // Hover veya seçim durumu için smooth geçiş
    cursor: 'pointer',
  },
  unmatchedPhoto: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  unmatchedPhotoo: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  capturedPhotos: {
    display: 'flex',
    gap: '10px',
    padding: '10px',
  },
  photoWrapper: {
    display: 'inline-block', // Görsellerin yan yana olmasını sağlar
    marginRight: '10px', // Görseller arasında boşluk
    position: 'relative',
  },
  clearIcon: {
    fontSize: '24px',
    color: 'red',
    cursor: 'pointer',
    marginLeft: '10px',
  },
  capturedPhoto: {
    width: '100px',
    height: '100px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  deleteIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    color: 'red',
    fontSize: '20px',
    cursor: 'pointer',
    zIndex: 1, // Üstte görünmesini sağlıyoruz
  },
  capturedPhotoWrapper: {
    position: 'relative',
  },
  reportCard: {
    marginBottom: '20px',
  },
  reportTable: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  clickableRow: {
    cursor: 'pointer',
  },
  textDanger: {
    color: 'red',
  },
  reportHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  reportCardHeader: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  chevronIcon: {
    fontSize: '24px',
    cursor: 'pointer',
  },
  categoryCol: {
    marginBottom: '15px',
  },
  innerCard: {
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    minWidth: '160px',
    textAlign: 'center',
    padding: '10px',
    cursor: 'pointer',
  },
  categoryTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '10px',
    wordWrap: 'break-word',
  },
  iconContainer: {
    textAlign: 'center',
    marginBottom: '10px',
  },
  iconOptions: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '10px',
  },
  icon: {
    width: '50px',
    height: '50px',
    cursor: 'pointer',
  },
  saveButton: {
    marginTop: '20px',
  },
  photoListContainer: {
    overflowX: 'auto', // Yatay scroll
    display: 'flex',
    maxWidth: '100%',
    marginTop: '10px',
  },
  photoList: {
    display: 'inline-flex',
    gap: '10px',
  },
  imageWrapper: {
    position: 'relative', // Badge'i ikonun üzerine yerleştirmek için relative pozisyonlama
    display: 'inline-block', // İçerikleri bir arada tutmak için
  },
  photoCountBadge: {
    position: 'absolute',
    top: '0px',  // Sağ üst köşeye yerleştirmek için top'u küçülttüm
    right: '0px',  // Sağ üst köşeye yerleştirmek için right'ı küçülttüm
    backgroundColor: 'red', // Arka plan rengi
    color: 'white',
    borderRadius: '50%', // Yuvarlak etiket
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    zIndex: 1, // Üstte kalmasını sağlıyoruz
  },
  photoCountBadgee: {
    position: 'absolute',
    top: '-5px',  // Sağ üst köşeye yerleştirmek için top'u küçülttüm
    right: '-5px',  // Sağ üst köşeye yerleştirmek için right'ı küçülttüm
    backgroundColor: 'blue', // Arka plan rengi
    color: 'white',
    borderRadius: '50%', // Yuvarlak etiket
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    zIndex: 1, // Üstte kalmasını sağlıyoruz
  },
  photoCountBadgeee: {
    position: 'absolute',
    top: '-5px',  // Sağ üst köşeye yerleştirmek için top'u küçülttüm
    right: '20px',  // Sağ üst köşeye yerleştirmek için right'ı küçülttüm
    backgroundColor: 'red', // Arka plan rengi
    color: 'white',
    borderRadius: '50%', // Yuvarlak etiket
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    zIndex: 1, // Üstte kalmasını sağlıyoruz
  },
  photoCountBadgeBlue: {
    backgroundColor: 'blue', // Fuar sonrası için mavi sayaç
    color: 'white',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  zoomControl: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  zoomSlider: {
    position: 'absolute',
    writingMode: 'bt-lr',  // Slider'ın dikey olması için
    transform: 'rotate(270deg)', // Dikey slider
    width: '150px', // Slider genişliği (dikey görünümde uzunluk)
    marginBottom: '10px',
  },
  photo: {
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  noPhotosText: {
    color: 'gray',
    fontStyle: 'italic',
  },
  categoryButtonsContainer: {
    marginLeft: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
  },
};



export default Area;
