import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Row, Col, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import * as XLSX from 'xlsx';

// Görsellerin import edilmesi
import Evet from "../../assets/images/Evet.png";
import Hayır from "../../assets/images/Hayır.png";
import Yok from "../../assets/images/Yok.png";
import Belirtilmedi from "../../assets/images/Belirtilmedi.png";
import IMG from "../../assets/images/IMG.png";

const Reports = () => {
  const [stands, setStands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSalon, setSelectedSalon] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [LIMIT, setLIMIT] = useState(10);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [reportStatuses, setReportStatuses] = useState({});
  const [reportStatusess, setReportStatusess] = useState({});
  const [categoryPhotoCounts, setCategoryPhotoCounts] = useState({});

  const [categoryPhotoCountsPre, setCategoryPhotoCountsPre] = useState({});
  const [categoryPhotoCountsPost, setCategoryPhotoCountsPost] = useState({});
  const [categoryStatuses, setCategoryStatuses] = useState({});

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCategoryPhotos, setSelectedCategoryPhotos] = useState([]);
  const [selectedStandId, setSelectedStandId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [error, setError] = useState(null);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);  // Silme işlemi için bir yükleniyor durumu



  const userId = localStorage.getItem('userId'); // Kullanıcı ID'si localStorage'dan alınır

  const toggleModal = () => {
    setModalOpen(!modalOpen);

    if (!modalOpen) {
      // Modal kapandığında verileri sıfırla
      setSelectedCategoryPhotos([]);
    }
  };

  const deleteUnusedPhotos = async () => {
    setDeleting(true);  // Yükleniyor durumuna geç
    setError(null);  // Herhangi bir hata durumunu sıfırla

    try {
      await axios.delete('https://e.sakagrup.com/delete-unused-photos');  // Backend'e istek
      setDeleteModalOpen(false);  // Modalı kapat
      await fetchStands();  // Görselleri ve standları yeniden yükle
    } catch (error) {
      console.error('Kullanılmayan görseller silinirken hata oluştu:', error);
      setError('Kullanılmayan görseller silinirken bir hata oluştu.');
    } finally {
      setDeleting(false);  // Yükleniyor durumundan çık
    }
  };


  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const openPhotoModal = async (standId, categoryId) => {
    if (!standId || !categoryId) {
      console.error('Stand ID veya Kategori ID eksik:', { standId, categoryId });
      return;  // Eksik parametreler varsa işlemi durdur
    }

    setSelectedStandId(standId);
    setSelectedCategoryId(categoryId);
    setModalOpen(true);  // Modalı açıyoruz

    try {
      const categoryPhotosResponse = await axios.get(`https://e.sakagrup.com/stand/${standId}/category/${categoryId}/photos`);

      setSelectedCategoryPhotos(categoryPhotosResponse.data); // Kategorideki görselleri kaydet
    } catch (error) {
      console.error('Görseller alınırken hata oluştu:', error);
    }
  };

  const removePhotoCategory = async (photoId) => {
    try {
      await axios.put(`https://e.sakagrup.com/photo/${photoId}/remove-category`);
      // Kategorideki görselleri yeniden çek
      openPhotoModal(selectedStandId, selectedCategoryId);
      await fetchStands();
    } catch (error) {
      console.error('Fotoğraf kategorisi silinirken hata oluştu:', error);
    }
  };

  const [confirmModalOpen, setConfirmModalOpen] = useState(false); // Modal state

  const toggleConfirmModal = () => {
    setConfirmModalOpen(!confirmModalOpen); // Modal açma/kapatma fonksiyonu
  };

  const handleConfirmDownload = () => {
    toggleConfirmModal(); // Modalı kapat
    downloadZip(); // ZIP indirme işlemini başlat
  };

  const downloadZip = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get('https://e.sakagrup.com/download-photos', {
        responseType: 'blob'  // Dosyayı indirmek için 'blob' kullanıyoruz
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'main_photos.zip');  // ZIP dosyasını indirme işlemi
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error('Error downloading ZIP:', err);
      setError('ZIP dosyası indirilirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  const categories = [
    { id: 1, name: 'stant_yapisi_ve_duvarlar', label: 'Stant Yapısı ve Duvarlar' },
    { id: 2, name: 'platform', label: 'Platform' },
    { id: 3, name: 'mobilya_ve_techizat', label: 'Mobilya ve Teçhizat' },
    { id: 4, name: 'aydinlatma', label: 'Aydınlatma' },
    { id: 5, name: 'alinlik_ve_bas_ustu', label: 'Alınlık ve Baş Üstü' },
    { id: 6, name: 'donatilmis_yapi', label: 'Donatılmış Yapı' },
    { id: 7, name: 'tavan', label: 'Tavan' },
    { id: 8, name: 'teshir_bilesenleri', label: 'Teşhir Bileşenleri' },
    { id: 9, name: 'zemin', label: 'Zemin' },
    { id: 10, name: 'grafikler_dekoratifler', label: 'Grafikler ve Dekoratifler' },
  ];


  // Standlar ve kategorilere göre fotoğraf sayıları
  const fetchCategoryPhotoCountsPre = async (standId) => {
    try {
      const response = await axios.get(`https://e.sakagrup.com/stand/${standId}/category-photo-counts-pre`);
      setCategoryPhotoCountsPre((prevState) => ({
        ...prevState,
        [standId]: response.data
      }));
    } catch (error) {
      console.error('Kategori fotoğraf sayıları getirilemedi:', error);
    }
  };

  const fetchCategoryPhotoCountsPost = async (standId) => {
    try {
      const response = await axios.get(`https://e.sakagrup.com/stand/${standId}/category-photo-counts-post`);
      setCategoryPhotoCountsPost((prevState) => ({
        ...prevState,
        [standId]: response.data
      }));
    } catch (error) {
      console.error('Kategori fotoğraf sayıları getirilemedi:', error);
    }
  };

  // Stand raporlarının durumunu kontrol etme
  const fetchStandReports = async (standId) => {
    try {
      const response = await axios.get(`https://e.sakagrup.com/stand/${standId}/reports`);
      setCategoryStatuses((prevState) => ({
        ...prevState,
        [standId]: response.data.fuaroncesi || {} // Fuar öncesi raporunu alıyoruz
      }));
    } catch (error) {
      console.error('Stand raporları getirilemedi:', error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0]; // Kullanıcının seçtiği dosya
    if (!file) {
      console.error('Dosya seçilmedi.');
      return;
    }

    // Dosyayı base64 formatına dönüştürme
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64Data = reader.result.split(',')[1]; // Base64 verisini elde ediyoruz (data kısmı hariç)

      const payload = {
        userid: userId, // localStorage'dan alınan kullanıcı ID
        standid: selectedStandId, // Seçilen stand ID
        categoryid: selectedCategoryId, // Seçilen kategori ID
        photo: base64Data, // Base64 formatındaki dosya verisi
        report_type: 'fuarsonrasi', // Örnek rapor tipi
      };

      // Gönderilen verileri loglamak
      console.log('Gönderilen payload:', payload);

      try {
        const response = await axios.post('https://e.sakagrup.com/save-photo', payload);
        if (response.data.success) {
          console.log('Görsel başarıyla yüklendi.');
          // Yükleme başarılı olduktan sonra tabloyu ve görselleri güncelle
          openPhotoModal(selectedStandId, selectedCategoryId);
          await fetchStands();
        } else {
          console.error('Sunucudan başarı durumu false döndü:', response.data);
        }
      } catch (error) {
        // Hatanın daha fazla detayını loglamak
        if (error.response) {
          console.error('Görsel yüklenirken hata oluştu:', error.response.data);
          console.error('Status:', error.response.status);
          console.error('Headers:', error.response.headers);
        } else if (error.request) {
          console.error('Sunucudan yanıt alınamadı:', error.request);
        } else {
          console.error('İstek gönderilirken hata oluştu:', error.message);
        }
      }
    };

    // Dosyanın base64 formatına çevrilmesini başlatır
    reader.readAsDataURL(file);
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new(); // Yeni bir workbook oluştur
    const worksheetData = [];

    // Tablodaki başlıkları ekle
    const headers = ['Ana Fotoğraf', 'Kod', 'Salon', 'İsim', 'Fuar Öncesi', 'Fuar Sonrası', 'Tür', 'Statü', ...categories.map(c => c.label)]; // Kategori başlıkları sadece bir kere olacak
    worksheetData.push(headers);

    // Tablodaki her satırı ekle
    stands.forEach((stand) => {
      const rowData = [
        'IMG', // Ana fotoğraf sütununu Excel'de sadece 'IMG' olarak gösteriyoruz
        stand.kod,
        stand.salon,
        stand.isim,
        reportStatuses[stand.id] || 'Belirtilmedi',
        reportStatusess[stand.id] || 'Belirtilmedi',
        stand.type || 'Belirtilmedi',
        calculateStatus(categoryStatuses, stand.id),  // Statü yerine calculateStatus fonksiyonunu kullanıyoruz
        ...categories.map(category => {
          const status = categoryStatuses?.[stand.id]?.[category.name] || 'Belirtilmedi'; // Durum (Evet, Hayır, Yok, Belirtilmedi)
          const preCount = categoryPhotoCountsPre[stand.id]?.find((c) => c.categoryid === category.id)?.photoCount || 0;
          const postCount = categoryPhotoCountsPost[stand.id]?.find((c) => c.categoryid === category.id)?.photoCount || 0;
          return `${status} (${preCount},${postCount})`; // Örnek: Evet(1,5)
        })
      ];

      worksheetData.push(rowData);
    });

    // Worksheet oluştur ve workbook'a ekle
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Stant Raporları');

    // Excel dosyasını indir
    XLSX.writeFile(workbook, 'Stand_Raporlari.xlsx');
  };



  const fetchStands = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://e.sakagrup.com/stands`, {
        params: {
          limit: LIMIT,
          offset: (currentPage - 1) * LIMIT,
          search: searchQuery,
          salon: selectedSalon,
        },
      });
      setStands(response.data);
      for (const stand of response.data) {
        fetchCategoryPhotoCountsPre(stand.id);
        fetchCategoryPhotoCountsPost(stand.id);
        fetchStandReports(stand.id); // Her stand için rapor verileri çekiliyor
        checkReports(stand.id); // Her stand için rapor durumu kontrolü
        checkReportss(stand.id); // Her stand için rapor durumu kontrolü
      }
    } catch (error) {
      console.error('Standlar getirilemedi:', error);
    }
    setLoading(false);
  };

  const checkReports = async (standId) => {
    try {
      const response = await axios.get(`https://e.sakagrup.com/stand/${standId}/status`, {
        params: { reportType: 'fuaroncesi' }
      });
      setReportStatuses((prevState) => ({
        ...prevState,
        [standId]: response.data.badgeColor
      }));
    } catch (error) {
      console.error('Rapor durumu kontrolü yapılırken hata oluştu:', error);
      setReportStatuses((prevState) => ({
        ...prevState,
        [standId]: 'gray'
      }));
    }
  };

  const checkReportss = async (standId) => {
    try {
      const response = await axios.get(`https://e.sakagrup.com/stand/${standId}/statuss`, {
        params: { reportType: 'fuarsonrasi' }
      });
      setReportStatusess((prevState) => ({
        ...prevState,
        [standId]: response.data.badgeColor
      }));
    } catch (error) {
      console.error('Rapor durumu kontrolü yapılırken hata oluştu:', error);
      setReportStatusess((prevState) => ({
        ...prevState,
        [standId]: 'gray'
      }));
    }
  };

  useEffect(() => {
    fetchStands();
  }, [LIMIT, currentPage, searchQuery, selectedSalon]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSalonChange = (e) => {
    setSelectedSalon(e.target.value);
    setCurrentPage(1); // Sayfayı başa al
  };

  const handleLimitChange = (limit) => {
    setLIMIT(limit);
    setCurrentPage(1); // Sayfayı başa al
  };

  const nextPage = () => setCurrentPage((prevPage) => prevPage + 1);
  const prevPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));

  const calculateStatus = (categoryStatuses, standId) => {
    // İlk 4 kategori: Stand Yapısı ve Duvarlar, Platform, Mobilya ve Teçhizat, Aydınlatma
    const firstFourCategories = ['stant_yapisi_ve_duvarlar', 'platform', 'mobilya_ve_techizat', 'aydinlatma'];

    // İkinci 4 kategori: Alınlık ve Baş Üstü, Donatılmış Yapı, Tavan, Teşhir Bileşenleri
    const secondFourCategories = ['alinlik_ve_bas_ustu', 'donatilmis_yapi', 'tavan', 'teshir_bilesenleri'];

    // Son 2 kategori: Zemin, Grafikler ve Dekoratifler
    const lastTwoCategories = ['zemin', 'grafikler_dekoratifler'];

    // İlk 4 kategoride herhangi bir 'hayır' varsa "Dissposible" döndür
    const firstFourStatuses = firstFourCategories.map(cat => categoryStatuses[standId]?.[cat]);
    if (firstFourStatuses.includes('hayır')) {
      return 'Dissposible';
    }

    // Eğer ilk 4'te hayır yoksa "Bronze" olabilir
    const secondFourStatuses = secondFourCategories.map(cat => categoryStatuses[standId]?.[cat]);
    if (firstFourStatuses.every(status => status === 'evet' || status === 'yok')) {
      // Eğer ikinci 4 kategori de "evet" veya "yok" ise "Silver"
      if (secondFourStatuses.every(status => status === 'evet' || status === 'yok')) {
        const lastTwoStatuses = lastTwoCategories.map(cat => categoryStatuses[standId]?.[cat]);
        // Son 2 kategori de "evet" veya "yok" ise "Gold"
        if (lastTwoStatuses.every(status => status === 'evet' || status === 'yok')) {
          return 'Gold';
        }
        return 'Silver';
      }
      return 'Bronze';
    }

    return 'Aktif'; // Varsayılan durum
  };

  const renderBadge = (categoryStatus, photoCountPre, photoCountPost, isAnaFoto, standId, categoryId) => {
    let imgSrc;

    // Ana fotoğraf kontrolü
    if (isAnaFoto) {
      imgSrc = IMG;
    } else {
      // Status değerine göre uygun resim kaynağını belirliyoruz
      switch (categoryStatus) {
        case 'evet':
          imgSrc = Evet;
          break;
        case 'hayır':
          imgSrc = Hayır;
          break;
        case 'yok':
          imgSrc = Yok;
          break;
        default:
          imgSrc = Belirtilmedi; // Eğer status 'evet', 'hayır' veya 'yok' değilse 'Belirtilmedi' görseli kullanılacak
      }
    }

    // Stand ID veya kategori ID boşsa hata logu yazdırıyoruz
    if (!standId || !categoryId) {
      console.error('Stand ID veya Kategori ID eksik:', { standId, categoryId });
      return null; // Eksikse badge'i render etme
    }

    return (
      <div
        style={{ position: 'relative', display: 'inline-block' }}
        onClick={() => openPhotoModal(standId, categoryId)}  // Doğru standId ve categoryId ile modalı açmak
      >
        {/* Görsel render ediliyor */}
        <img
          src={imgSrc}
          alt={categoryStatus}
          style={{ width: '50px', height: '50px', cursor: 'pointer' }}
        />

        {/* Fuar Öncesi Fotoğraf Sayısı */}
        {photoCountPre > 0 && (
          <span
            style={{
              position: 'absolute',
              top: '-5px',
              right: '10px',
              backgroundColor: 'red',
              color: 'white',
              borderRadius: '50%',
              padding: '2px 5px',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            {photoCountPre}
          </span>
        )}

        {/* Fuar Sonrası Fotoğraf Sayısı */}
        {photoCountPost > 0 && (
          <span
            style={{
              position: 'absolute',
              top: '-5px',
              right: '-5px',
              backgroundColor: 'blue',
              color: 'white',
              borderRadius: '50%',
              padding: '2px 5px',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            {photoCountPost}
          </span>
        )}
      </div>
    );
  };


  return (
    <div className="page-content">
      <Container fluid>
        <h1 className="mb-4">Stant Raporları</h1>

        {/* Arama ve Filtreleme */}
        <Row className="mb-4">
          <Col md="4">
            <Input
              type="text"
              placeholder="Stant ismi veya kodu ile ara"
              value={searchQuery}
              onChange={handleSearch}
            />
          </Col>
          <Col md="3">
            <Input
              type="select"
              value={selectedSalon}
              onChange={handleSalonChange}
            >
              <option value="">Tüm Salonlar</option>
              <option value="4">Salon 4</option>
              <option value="5">Salon 5</option>
              <option value="6">Salon 6</option>
              <option value="7">Salon 7</option>
              <option value="8">Salon 8</option>
              <option value="Fuaye">Fuaye</option>
              <option value="X">Salon X</option>
            </Input>
          </Col>
          <Col md="1">
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle>
                Gösterim: {LIMIT}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleLimitChange(10)}>10</DropdownItem>
                <DropdownItem onClick={() => handleLimitChange(25)}>25</DropdownItem>
                <DropdownItem onClick={() => handleLimitChange(50)}>50</DropdownItem>
                <DropdownItem onClick={() => handleLimitChange(100)}>100</DropdownItem>
                <DropdownItem onClick={() => handleLimitChange(500)}>500</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col md="1">
            <Button color="success" onClick={exportToExcel} className="mb-4">
              Excel'e Aktar
            </Button>
          </Col>
          <Col md="1">
            <Button color="primary" onClick={toggleConfirmModal} disabled={loading}>
              {loading ? 'İndiriliyor...' : 'Görselleri İndir'}
            </Button>
          </Col>
          <Col md="1">
            <Button style={{ width: 200 }} color="secondary" disabled={loading || deleting} onClick={toggleDeleteModal}>
              {deleting ? 'Siliniyor...' : 'Kullanılmayan Görselleri Sil'}
            </Button>
          </Col>
        </Row>

        {/* Tablo */}
        <Table bordered responsive className="mt-4">
          <thead>
            <tr>
              <th>Ana Fotoğraf</th> {/* Ana Fotoğraf en sola alındı */}
              <th>Kod</th>
              <th>Salon</th>
              <th>İsim</th>
              <th>Fuar Öncesi</th>
              <th>Fuar Sonrası</th>
              <th>Tür</th>
              <th>Statü</th>
              {categories.map((category) => (
                <th key={category.id}>{category.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {stands.map((stand) => (
              <tr key={stand.id}>
                {/* Ana Fotoğraf hücresini en sola aldık ve görsel yoksa arkaplan rengini saydam kırmızı yapıyoruz */}
                <td
                  style={{
                    backgroundColor: !categoryPhotoCountsPre[stand.id]?.find((c) => c.categoryid === 11)?.photoCount &&
                      !categoryPhotoCountsPost[stand.id]?.find((c) => c.categoryid === 11)?.photoCount
                      ? 'rgba(150, 150, 150, 1)' // photoCount yoksa saydam kırmızı arkaplan
                      : 'inherit',
                  }}
                >
                  {/* Ana fotoğraf için IMG render ediliyor */}
                  <div
                    style={{ position: 'relative', display: 'inline-block' }}
                    onClick={() => openPhotoModal(stand.id, 11)}  // 11 kategorisinin Ana Fotoğraf olduğunu varsayıyoruz
                  >
                    <img
                      src={IMG}
                      alt="Ana Fotoğraf"
                      style={{ width: '50px', height: '50px', cursor: 'pointer' }}  // Modal açmak için tıklanabilirlik eklendi
                    />

                    {/* Fuar Öncesi Fotoğraf Sayısı */}
                    {categoryPhotoCountsPre[stand.id]?.find((c) => c.categoryid === 11)?.photoCount > 0 && (
                      <span
                        style={{
                          position: 'absolute',
                          top: '-5px',
                          right: '10px',
                          backgroundColor: 'red',
                          color: 'white',
                          borderRadius: '50%',
                          padding: '2px 5px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                      >
                        {categoryPhotoCountsPre[stand.id]?.find((c) => c.categoryid === 11)?.photoCount}
                      </span>
                    )}

                    {/* Fuar Sonrası Fotoğraf Sayısı */}
                    {categoryPhotoCountsPost[stand.id]?.find((c) => c.categoryid === 11)?.photoCount > 0 && (
                      <span
                        style={{
                          position: 'absolute',
                          top: '-5px',
                          right: '-5px',
                          backgroundColor: 'blue',
                          color: 'white',
                          borderRadius: '50%',
                          padding: '2px 5px',
                          fontSize: '12px',
                          fontWeight: 'bold',
                        }}
                      >
                        {categoryPhotoCountsPost[stand.id]?.find((c) => c.categoryid === 11)?.photoCount}
                      </span>
                    )}
                  </div>
                </td>

                <td>{stand.kod}</td>
                <td>{stand.salon}</td>
                <td>{stand.isim}</td>

                <td>
                  <span
                    style={{
                      backgroundColor: reportStatuses[stand.id] || 'gray',
                      color: 'white',
                      borderRadius: '12px',
                      padding: '6px 10px',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    <i className="bx bx-store" style={{ fontSize: '18px' }}></i>
                  </span>
                </td>
                <td>
                  <span
                    style={{
                      backgroundColor: reportStatusess[stand.id] || 'gray',
                      color: 'white',
                      borderRadius: '12px',
                      padding: '6px 10px',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    <i className="bx bx-store" style={{ fontSize: '18px' }}></i>
                  </span>
                </td>
                <td>
                  {stand.type === 'Ah?ap' ? 'Ahşap' : stand.type || 'Belirtilmedi'}
                </td>
                <td>{calculateStatus(categoryStatuses, stand.id)}</td>

                {categories.map((category) => {
                  const categoryStatus = categoryStatuses?.[stand.id]?.[category.name] || 'belirtilmedi';
                  const photoCountPre = categoryPhotoCountsPre[stand.id]?.find((c) => c.categoryid === category.id)?.photoCount || 0;
                  const photoCountPost = categoryPhotoCountsPost[stand.id]?.find((c) => c.categoryid === category.id)?.photoCount || 0;

                  return (
                    <td
                      key={category.id}
                      style={{
                        backgroundColor: (photoCountPre === 0 && photoCountPost === 0) ? 'rgba(150, 150, 150, 1)' : 'inherit', // Eğer hem fuar öncesi hem de sonrası 0 ise arka planı kırmızı yap
                      }}
                    >
                      {renderBadge(categoryStatus, photoCountPre, photoCountPost, false, stand.id, category.id)} {/* stand.id ve category.id doğru geçiyor */}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Sayfalama */}
        <Row className="justify-content-around align-items-center mb-3">
          <Col xs="auto">
            <Button color="secondary" onClick={prevPage} disabled={currentPage === 1}>
              Geri
            </Button>
          </Col>
          <Col xs="auto">
            <Button color="secondary" onClick={nextPage} disabled={stands.length < LIMIT}>
              İleri
            </Button>
          </Col>
        </Row>

        {loading && <p>Veriler yükleniyor...</p>}

        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            Stant: {selectedStandId}, Kategori: {selectedCategoryId}
          </ModalHeader>
          <ModalBody>
            {/* Kategorideki Seçilmiş Görseller */}
            <h5>Kategorideki Seçilmiş Görseller</h5>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {selectedCategoryPhotos.length === 0 ? (
                <p>Kategoride seçilmiş görsel yok.</p>
              ) : (
                selectedCategoryPhotos.map(photo => (
                  <div key={photo.id} style={{ position: 'relative', margin: '10px' }}>
                    <img
                      src={`https://e.sakagrup.com/${photo.photopath}`}
                      alt="Seçilmiş Görsel"
                      style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                      onClick={() => setSelectedImage(photo.photopath)}
                    />
                    <i
                      className="bx bx-x-circle"
                      style={{
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        backgroundColor: 'transparent',
                        color: 'red',
                        fontSize: '24px',
                        cursor: 'pointer'
                      }}
                      onClick={() => removePhotoCategory(photo.id)}
                    />
                  </div>
                ))
              )}
            </div>
            <Input type="file" onChange={handleFileUpload} />
          </ModalBody>
        </Modal>
        <Modal isOpen={confirmModalOpen} toggle={toggleConfirmModal}>
          <ModalHeader toggle={toggleConfirmModal}>Onay</ModalHeader>
          <ModalBody>
            Tüm görselleri indirmek istediğinizden emin misiniz?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={toggleConfirmModal}>Vazgeç</Button>
            <Button color="primary" onClick={handleConfirmDownload}>Evet, İndir</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={deleteModalOpen} toggle={toggleDeleteModal}>
          <ModalHeader toggle={toggleDeleteModal}>Onay</ModalHeader>
          <ModalBody>
            Kullanılmayan stant veya kategoriye eşleşmemiş tüm görselleri silmek istediğinize emin misiniz?
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDeleteModal}>
              İptal
            </Button>
            <Button color="danger" onClick={deleteUnusedPhotos}>
              {deleting ? 'Siliniyor...' : 'Evet, Sil'}
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  );
};

export default Reports;
