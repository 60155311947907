import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  const [isArea, setIsArea] = useState(false);
  const [isDocs, setIsDocs] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState === "Area") {
      history("/Area");
      setIsArea(false);
      document.body.classList.add("twocolumn-panel");
    }
    if (iscurrentState === "Docs") {
      history("/Docs");
      setIsDocs(false);
      document.body.classList.add("twocolumn-panel");
    }
  }, [
    history,
    iscurrentState,
    isArea,
    isDocs
  ]);

  const menuItems = [
    {
      label: "Menü",
      isHeader: true,
    },
    {
      id: "Dashboard",
      label: "Ana Sayfa",
      icon: "bx bxs-home",
      link: "/Dashboard",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Dashboard");
      },
    },
    {
      id: "Area",
      label: "Saha",
      icon: "bx bxs-camera",
      link: "/Area",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Area");
      },
    },
    {
      id: "Docs",
      label: "Dosyalar",
      icon: "bx bxs-file-image",
      link: "/Docs",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Docs");
      },
    },
    {
      id: "Reports",
      label: "Raporlar",
      icon: "bx bxs-report",
      link: "/Reports",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Reports");
      },
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
