import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import axios from 'axios';
import config from '../../slices/config.js';

const getInitials = (firstName, lastName) => {
    if (!firstName || !lastName) return "A";
    return `${firstName[0]}${lastName[0]}`;
};

const ProfileDropdown = () => {
    const [Name, setName] = useState(null);
    const [userName, setUserName] = useState(null);
    const [initials, setInitials] = useState(null);
    const [userGroupName, setUserGroupName] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            const userId = localStorage.getItem('userId');
            const token = localStorage.getItem('token');
            const authHeader = 'Basic ' + btoa('fds_mobile:Rk3%${Vx]x');

            if (userId && token) {
                try {
                    const userResponse = await axios.get(`${config.api.User}${userId}`, {
                        headers: {
                            Authorization: authHeader,
                        },
                    });
                    const userInfo = userResponse.data;

                    const groupResponse = await axios.get(`${config.api.Group}${userId}/group`, {
                        headers: {
                            Authorization: authHeader,
                        },
                    });
                    const groupInfo = groupResponse.data;

                    if (userInfo) {
                        setUserName(`${userInfo.userName} ${userInfo.userSurname}`);
                        setName(`${userInfo.userName}`);
                        setInitials(getInitials(userInfo.userName, userInfo.userSurname));
                        setUserGroupName(groupInfo.userGroupName);
                    }
                } catch (error) {
                    console.error('Error fetching user info:', error);
                }
            }
        };

        fetchUserData();
    }, []);

    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const handleLogout = () => {
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('groupInfo');
        localStorage.removeItem('user');
        navigate('/login');
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <div className="rounded-circle header-profile-user d-flex align-items-center justify-content-center bg-primary text-white" style={{ width: 40, height: 40, fontSize: 20 }}>
                            {initials}
                        </div>
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userGroupName}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Hoşgeldin {Name}</h6>
                    <DropdownItem className='p-0' onClick={handleLogout}>
                        <Link to="/pages-profile-settings" className="dropdown-item">
                            <i className="bx bx-exit text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Çıkış Yap</span>
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;
